.universe {
  width:100%;
  min-height:100%;
  display:flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .text {
    width:min(300px,90%);
    font-family: 'century_gothicregular';
    text-align:center;
    font-size:1em;
  }
  .playgrounds {
    width:100%;
    flex-grow: 1;
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width:min(300px,100%);
    margin-bottom:40px;
    .swiper {
      width:92.5%;
      min-height:100%;
      overflow:visible;
      .swiper-slide {
        height:100%;
        text-align: center;
        opacity:0.5;
        transition:opacity 0.2s;
        &.swiper-slide-active {
          opacity:1;
        }
        .swiper-action {
          width:100%;
          display:flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          text-align:center;
          margin:5px 0;
        }
      }
      .swiper-button-prev, .swiper-button-next {
        top:calc(100% + 25px);
        color:#000;
        &::after {
          font-size:25px;
        }
      }
      .swiper-button-prev {
        left:25%;
      }
      .swiper-button-next {
        right:25%;
      }
    }
    .playground-title {
      margin-left:1.5%;
      padding:15px 0;
      text-align:left;
      .trait {
        background-color: #000;
        height:3px;
        width:50px;
        margin-top:15px;
      }
    }
    .playground-visuel {
      margin-left:1.5%;
      width:97%;
      padding-bottom:180%;
      position:relative;
      img {
        position:absolute;
        top:0;
        left:0;
        width:100%;
        height:100%;
        object-fit: contain;
        object-position: left;
      }
    }
  }
}
