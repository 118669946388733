html,body {
  background-color:#fff;
  margin:0;
  padding:0;
  width:100%;
  height:100%;
  font-family: 'Roboto';
}
@keyframes rootfade {
  0% { opacity:0; }
  83% { opacity:0; }
  100% { opacity:1; }
}

#root {
  position:fixed;
  top:0;
  left:0;
  width:100%;
  height:100%;
  animation: 1.2s rootfade;
  background:url('./assets/background.jpg');
  background-size: cover;
  background-position: center;
  font-family: 'hennessy_sansregular';
  .mask {
    position:absolute;
    top:0;
    left:0;
    width:100%;
    height:100%;
    background: linear-gradient(#FFF,#FFF2);
    overflow-x:hidden;
  }
  a {
    color:#000;
    text-decoration:underline;
  }
  .btn {
    user-select: none;
    display:block;
    background-color: #000;
    cursor: pointer;
    &:hover, &:active {
      background-color: #333;
    }
    padding:10px 15px;
    border-radius:4px;
    color:#fff;
    width:90%;
    box-sizing: border-box;
    &.disabled {
      background-color: #666;
      cursor: auto;
      &:hover, &:active {
        background-color: #666;
      }
    }
    &.inactive {
      cursor: pointer;
      background-color: #666;
    }
  }
  a.btn {
    color:#fff;
    text-decoration:none;
  }

  .spacer {
    height:15px;
  }
}
.MuiInputBase-root {
  background-color:#FFF;
}
.MuiFormLabel-root.MuiInputLabel-root{
  font-family: 'hennessy_sansregular';
  padding:0 0.5em;
  margin-left:-0.25em;
  background-color:#FFF;
  border-radius: 4px;
  &.Mui-focused {
    padding:0 0.5em;
    margin-left:-0.25em;
    background-color:#FFF;
    border-radius: 4px;
  }
}
.logo-small {
  width:min(130px,90%);
  height:15vh;
  margin:15px 0;
  display:flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  svg {
    width:100%;
    height:100%;
  }
}
.action {
  width:min(300px,100%);
  display:flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align:center;
  margin:15px 0;
}

@font-face {
    font-family: 'druk_wide_boldbold';
    src: url('./assets/fonts/druk_wide_bold-webfont.woff2') format('woff2'),
         url('./assets/fonts/druk_wide_bold-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'century_gothicbold';
    src: url('./assets/fonts/gothicb-webfont.woff2') format('woff2'),
         url('./assets/fonts/gothicb-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'century_gothicitalic';
    src: url('./assets/fonts/gothici-webfont.woff2') format('woff2'),
         url('./assets/fonts/gothici-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'century_gothicregular';
    src: url('./assets/fonts/gothic-webfont.woff2') format('woff2'),
         url('./assets/fonts/gothic-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'century_gothicbold_italic';
    src: url('./assets/fonts/gothicbi-webfont.woff2') format('woff2'),
         url('./assets/fonts/gothicbi-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'hennessy_sans_1765regular';
    src: url('./assets/fonts/hennessysans1765-webfont.woff2') format('woff2'),
         url('./assets/fonts/hennessysans1765-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'hennessy_sans_1765_appregular';
    src: url('./assets/fonts/hennessysans1765_a-webfont.woff2') format('woff2'),
         url('./assets/fonts/hennessysans1765_a-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'hennessy_serif_1765regular';
    src: url('./assets/fonts/hennessyserif1765-webfont.woff2') format('woff2'),
         url('./assets/fonts/hennessyserif1765-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'hennessy_serif_1765_appRg';
    src: url('./assets/fonts/hennessyserif1765_a-webfont.woff2') format('woff2'),
         url('./assets/fonts/hennessyserif1765_a-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}
@font-face {
    font-family: 'hennessy_sansregular';
    src: url('./assets/fonts/hennessysans-webfont.woff2') format('woff2'),
         url('./assets/fonts/hennessysans-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'hennessy_serifregular';
    src: url('./assets/fonts/hennessyserif-webfont.woff2') format('woff2'),
         url('./assets/fonts/hennessyserif-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
