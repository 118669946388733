.input {
  width:100%;
  min-height:100%;
  display:flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .form {
    width:min(300px,90%);
    flex-grow: 1;
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .action {
    .terms {
      display:flex;
      justify-content: center;
      align-items: center;
      font-size:0.8em;
      user-select: none;
    }
  }
}
